import React, { useContext, useState } from "react";
import clsx from "clsx";
import Link from "next/link";
import NewAccount from "components/login/new-account";
import Button from "@/components/fields/button";
// import { } from "next-auth/react"

import miscStyles from "styles/misc.module.css";
import styles from "styles/signup/signup.module.css";
import { useRouter } from "next/router";
import { StoreContext } from "@/context";
import IsLoggedOutWrapper from "@/components/IsLoggedOutWrapper";
import { axios } from "@/utils/axios";
import toast from "react-hot-toast";
import ErrorTag from "@/components/ErrorTag";
import { signIn } from "next-auth/react";
import { signUpAccount } from "@/services/auth";
import CryptoFooter from "@/components/Footer/CryptoFooter";
import Toast from "@/components/Toast";
import Head from "next/head";
import Script from "next/script";

export default function Signup() {
  const context = useContext(StoreContext);
  const authAction = context.actions.auth;
  const [visible, setVisible] = useState(false);
  const [pageError, setPageError] = useState(null);
  const router = useRouter();

  const SignInPrompt = () => (
    <div className={styles.promptContainer}>
      <p className={styles.prompt}>
        Already have an account?{" "}
        <Link href={"/login"}>
          <span className={styles.promptLink}>Sign in</span>
        </Link>
      </p>
    </div>
  );

  return (
    <IsLoggedOutWrapper>
      <Head>
        <title>CryptoSports | Sign up</title>
        <Script src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_CONFIG_ANALYTICS}`} />
        <Script id="google-analytics">
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${process.env.NEXT_PUBLIC_CONFIG_ANALYTICS}');
          `}
        </Script>
      </Head>
      <div>
        <div className={styles.loginContent}>
          <div className={styles.titleGroup}>
            <img
              src="/images/cslogo.svg"
              alt="CryptoSports"
              className={styles.logoSvg}
            />
            <h4 className={clsx(miscStyles.tagline)}>Collect. Play. Win.</h4>
          </div>
          {!visible ? (
            <div className={styles.banner}>
              <div className={clsx(styles.bar, styles.left)}></div>
              <img
                src="/images/Justin-Jefferson.png"
                alt="kevin card"
                className={styles.card}
              />
              <div className={clsx(styles.bar, styles.right)}></div>
            </div>
          ) : (
            <>
              <NewAccount
                onSubmit={async (fields) => {
                  setPageError(null);
                  try {
                    console.log("Form Fields", fields);
                    /** got to next page */
                    let signUpAccountResult = await signUpAccount(fields);
                    console.log("Submit result", signUpAccountResult);
                    if (signUpAccountResult.data?.success) {
                      authAction.setEmail(fields.email);
                      authAction.setPassword(fields.password);
                      authAction.setNewSignup(true);
                      router.push(`/signup/confirm/${fields.email}`);
                    } else if (
                      signUpAccountResult.data.error ||
                      signUpAccountResult.data.message
                    ) {
                      toast.error(() => (
                        <Toast
                          message={
                            signUpAccountResult.data.error ||
                            signUpAccountResult.data.message
                          }
                        />
                      ));
                      setPageError(() => (
                        <Toast
                          message={
                            signUpAccountResult.data.error ||
                            signUpAccountResult.data.message
                          }
                        />
                      ));
                    }
                  } catch (error: any) {
                    console.log(error);
                    toast.error(() => (
                      <Toast
                        message={
                          error.response?.data?.message ||
                          error.error_message ||
                          "Unexpected Error"
                        }
                      />
                    ));
                    setPageError(() => (
                      <Toast
                        message={
                          error.response?.data?.message ||
                          error.error_message ||
                          "Unexpected Error"
                        }
                      />
                    ));
                  }
                }}
              />
              <div className={styles.centerError}>
                <ErrorTag message={pageError} />
              </div>
              <SignInPrompt />
              {/* <div className={styles.signupDivider}>
                <div className={styles.line} />
                <p className={styles.instruction}>Or sign up with</p>
                <div className={styles.line} />
              </div> */}
            </>
          )}
          <div className={styles.buttonGroup}>
            {!visible && (
              <Button
                text="Sign up with Email"
                className={clsx(styles.signupButton, styles.emailSignupButton)}
                onClick={() => {
                  // signUp();
                  setVisible(true);
                }}
              />
            )}
            {/* <Button
              className={clsx(styles.googleSignupButton, styles.signupButton)}
              onClick={() => {
                signIn("cognito");
              }}
              text={!visible ? "Sign up with Google" : "Google"}
              iconSrc="/images/icons/google.png"
            /> */}
            {/* <Button
                            className={clsx(
                                styles.appleSignupButton,
                                styles.signupButton
                            )}
                            onClick={() => {}}
                            text={!visible ? "Sign up with Apple" : "Apple"}
														iconSrc="/images/icons/apple.png"
                        /> */}
          </div>
          {!visible && <SignInPrompt />}
        </div>
      </div>

      <CryptoFooter />
    </IsLoggedOutWrapper>
  );
}
